import React, { useState } from "react";
import CarGif from "./car.gif";

export const MetaverseIframe = () => {
  const [isLoading, setIsLoading] = useState(true);
  const handleLoad = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };
  return (
    <div>
      {isLoading && (
        <img
          src={CarGif}
          style={{ position: "absolute", height: "100vh", width: "100vw" }}
        />
      )}
      <iframe
        onLoad={handleLoad}
        height={isLoading ? "0" : "1000"}
        width={isLoading ? "99%" : "100%"}
        src="https://x-frame-bypass.wowlabz.com/?url=https://play.decentraland.org/"
      ></iframe>
    </div>
  );
};
