import React, { useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useSelector, useDispatch } from "react-redux";

import {
  AvatarWrapper,
  BackpackWrapper,
  BagWrapper,
  CollectionWrapper,
  LandWrapper,
  Overlay,
  Tabs,
} from "./style";
import Cross from "../../assets/icons/close button.png";
import LandImg from "../../assets/img/land.png";
import CheckIcon from "../../assets/icons/check.png";

import BagItem from "../../components/bagItem/BagItem";
import {
  collections,
  options,
  renderThumb,
  sortConst,
} from "../../utils/constant";
import Dropdown from "../../components/dropdown/Dropdown";
import { useIsMobile } from "../../utils/utils";
import Empty from "../../components/Empty/Empty";
import { changeAvatar } from "../../redux/inventory/actionCreator";

function Inventory({ onCancel, onAddItemToBag, onRemoveItemfromBag }) {
  const isMobile = useIsMobile();
  const itemsState = useSelector((state) => state.inventory);
  const [currentTab, setCurrentTab] = useState("coll");
  const [currentFilter, setCurrentFilter] = useState("assets");
  const dispatch = useDispatch();
  const [dropDownState, setDropdownState] = useState({
    metaverseName: "Choose",
    collection: "Choose",
    sorter: "Recommanded",
  });
  const { metaverseName, collection, sorter } = dropDownState;

  const TabComponent = () => {
    return (
      <Tabs>
        <div
          className={`tab ${currentTab === "coll" ? "active" : "inactive"}`}
          onClick={() => setCurrentTab("coll")}
        >
          <span className="text">Your collections</span>
          <span className="item-count">
            {itemsState.collections.length < 10
              ? `0${itemsState.collections.length}`
              : itemsState.collections.length}
          </span>
        </div>
        <div
          className={`tab ${currentTab === "back" ? "active" : "inactive"}`}
          onClick={() => setCurrentTab("back")}
        >
          <span className="text">Backpack</span>
          <span className="item-count">
            {itemsState.backpackItems.length < 10
              ? `0${itemsState.backpackItems.length}`
              : itemsState.backpackItems.length}
          </span>
        </div>
      </Tabs>
    );
  };
  const AssetsComponent = () => {
    return itemsState.collections.length > 0 ? (
      <div className="wrapped-items">
        {itemsState.collections?.map((item) => {
          return (
            <BagItem
              key={item.id}
              className="modal-item"
              image={item.path}
              icon={true}
              onClick={() => onAddItemToBag(item)}
            ></BagItem>
          );
        })}
      </div>
    ) : (
      <Empty>No item's in collections</Empty>
    );
  };

  const AvatarComponent = () => {
    return (
      <AvatarWrapper>
        {itemsState.avatars.map((avatar) => {
          return (
            <div
              className={`avatar-back ${
                avatar.id === itemsState.currentAvatar.id
                  ? "active"
                  : "inactive"
              }`}
              onClick={() => dispatch(changeAvatar(avatar))}
            >
              <img className="avatar" src={avatar.path} />
              {avatar.id === itemsState.currentAvatar.id && (
                <img className="icon" src={CheckIcon} />
              )}
            </div>
          );
        })}
      </AvatarWrapper>
    );
  };

  const LandComponent = () => {
    return (
      <LandWrapper>
        <div className="land-back">
          <img className="land" src={LandImg} />
        </div>
        <div className="land-back">
          <img className="land" src={LandImg} />
        </div>
        <div className="land-back">
          <img className="land" src={LandImg} />
        </div>
      </LandWrapper>
    );
  };
  const WrappedItems = () => {
    return (
      <Scrollbars autoHide renderThumbVertical={renderThumb}>
        {currentFilter === "assets" && <AssetsComponent />}
        {currentFilter === "avatar" && <AvatarComponent />}
        {currentFilter === "land" && <LandComponent />}
      </Scrollbars>
    );
  };
  const CollectionComponent = () => {
    return (
      <div className="collection-content">
        {isMobile && <FilterComponent />}
        <div className="metaverse-name">
          <Dropdown
            selected={metaverseName}
            options={options}
            setSelected={(props) =>
              setDropdownState({
                ...dropDownState,
                metaverseName: props,
              })
            }
          />
        </div>
        <div className="collection-filter">
          <div className="filters">
            <span className="filter-label">Collections</span>
            <Dropdown
              background="dark"
              selected={collection}
              setSelected={(props) =>
                setDropdownState({
                  ...dropDownState,
                  collection: props,
                })
              }
              options={collections}
            />
          </div>
          <div className="filters">
            <span className="filter-label">Sort by</span>
            <Dropdown
              background="dark"
              selected={sorter}
              setSelected={(props) =>
                setDropdownState({
                  ...dropDownState,
                  sorter: props,
                })
              }
              options={sortConst}
            />
          </div>
        </div>
        <WrappedItems />
      </div>
    );
  };
  const FilterComponent = () => {
    return (
      <div className="collection-header">
        {!isMobile && <span className="title">Your collections</span>}
        <div className="sub-title-wrapper">
          <span
            className={`sub-title ${
              currentFilter === "assets" ? "active" : "inactive"
            }`}
            onClick={() => setCurrentFilter("assets")}
          >
            Assets
          </span>
          <span
            className={`sub-title ${
              currentFilter === "avatar" ? "active" : "inactive"
            }`}
            onClick={() => setCurrentFilter("avatar")}
          >
            Avatar
          </span>
          <span
            className={`sub-title ${
              currentFilter === "land" ? "active" : "inactive"
            }`}
            onClick={() => setCurrentFilter("land")}
          >
            Land
          </span>
        </div>
      </div>
    );
  };
  const BackPackComponent = () => {
    return (
      <div className="backpack-content">
        <div className="backpack-info">Assets to teleport to decentraland</div>
        <Scrollbars autoHide renderThumbVertical={renderThumb}>
          {itemsState.backpackItems.length > 0 ? (
            <div className="justify-center">
              <div className="wrapped-items">
                {itemsState.backpackItems?.map((item) => {
                  return (
                    <BagItem
                      key={item.id}
                      className="modal-item"
                      image={item.path}
                      icon={false}
                      onClick={() => onRemoveItemfromBag(item)}
                    ></BagItem>
                  );
                })}
              </div>
            </div>
          ) : (
            <Empty>No item's in backpack</Empty>
          )}
        </Scrollbars>
      </div>
    );
  };

  return (
    <Overlay>
      <div className="overlay-layout">
        <div className="cancel-wrapper">
          <img className="cancel-icon pointer" src={Cross} onClick={onCancel} />
        </div>
        <BagWrapper>
          {isMobile ? (
            <>
              {currentTab === "coll" && (
                <CollectionWrapper isMobile={isMobile}>
                  {isMobile && <TabComponent />}
                  {!isMobile && <FilterComponent />}
                  <CollectionComponent />
                </CollectionWrapper>
              )}
              {currentTab === "back" && (
                <BackpackWrapper isMobile={isMobile}>
                  {isMobile && <TabComponent />}
                  {!isMobile && (
                    <div className="backpack-header">
                      <span className="text">Backpack</span>
                      <span>1/4</span>
                    </div>
                  )}
                  <BackPackComponent />
                </BackpackWrapper>
              )}
            </>
          ) : (
            <>
              <CollectionWrapper isMobile={isMobile}>
                <FilterComponent />
                <CollectionComponent />
              </CollectionWrapper>
              <BackpackWrapper isMobile={isMobile}>
                <div className="backpack-header">
                  <span className="text">Backpack</span>
                  <span>
                    {itemsState?.backpackItems?.length}/
                    {itemsState?.collections?.length}
                  </span>
                </div>
                <BackPackComponent />
              </BackpackWrapper>
            </>
          )}
        </BagWrapper>
      </div>
    </Overlay>
  );
}

export default Inventory;
