import Image1 from "../assets/img/Frame 1322.png";
import Image2 from "../assets/img/Frame 1323.png";
import Image3 from "../assets/img/Frame 1324.png";
import Image4 from "../assets/img/Frame 1325.png";
import Image5 from "../assets/img/Frame 1326.png";
import Image6 from "../assets/img/Frame 1327.png";

export const SliderImages = [
  {
    id: 0,
    path: Image5,
    name: "Unknown",
    metaInfo: "Atari Casino",
    visited: "8 Mar 12:00",
  },
  {
    id: 1,
    path: Image1,
    name: "The Sandbox",
    metaInfo: "Location",
    visited: "3 Mar 12:00",
  },
  {
    id: 2,
    path: Image6,
    name: "Axie Infinity",
    metaInfo: "Atari Casino",
    visited: "4 Mar 12:00",
  },
  {
    id: 3,
    path: Image2,
    name: "Metahero",
    metaInfo: "Location",
    visited: "5 Mar 12:00",
  },
  {
    id: 4,
    path: Image3,
    name: "Somnimum Space",
    metaInfo: "Atari Casino",
    visited: "6 Mar 12:00",
  },
  {
    id: 5,
    path: Image4,
    name: "Horizon Worlds",
    metaInfo: "Location",
    visited: "7 Mar 12:00",
  },
];
