import styled from "styled-components";

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: darkblue;
  box-shadow: inset 0px 0px 12rem 16rem #0c0727;
  .bg {
    background: url("${(props) => props.background}");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: -1;
  }
  .floor {
    position: absolute;
    bottom: 0;
    zindex: 1;
    width: 100vw;
    height: 35vh;
    box-shadow: 0rem 0rem 15rem 7rem black;
  }
  .arrows {
    position: absolute;
    height: 5vw;
    z-index: 3;
  }
  .next {
    right: 6vw;
  }
  .prev {
    left: 6vw;
  }
  
  @media only screen and (max-width: 1024px) {
    height: 90vh;
    .floor {
      height: 44vh;
    }
  }
`;
export const SpringSliderWrapper = styled.div`
  width: 75vw;
  top: 0;
  height: 54vh;
  bottom: 0;
  margin: 0 auto;
  overflow: hidden;
  z-index: 3;
  img {
    pointer-events: none;
    user-select: none;
    object-fit: fill !important;
    height: 25vw;
  }
  @media only screen and (max-width: 1024px) {
    width: 85vw;
    // height: 25vh;
    img {
      height: 40vw;
    }
  }
  @media only screen and (max-width: 768px) {
    width: 100vw;
    img {
      height: 60vw;
    }
  }
`;
