import styled from "styled-components";

export const ItemWrapper = styled.div`
  height: inherit;
  position: relative;
  .item {
    height: inherit;
  }
  .icon {
    position: absolute;
    bottom: 0;
    right: -10px;
    cursor: pointer;
  }
`;
