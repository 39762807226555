import styled from "styled-components";

export const BottomCrontrolWrapper = styled.div`
  width: 83vw;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 10%;
  .dropdown {
    display: flex;
    align-items: end;
  }
  .meta-name {
    font-size: 23px;
    align-self: end;
    margin-bottom: 1.5rem;
  }
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    bottom: 0;
    .meta-name {
      display: none;
    }
  }
`;
export const AvatarWrapper = styled.div`
  z-index: 3;
  .avatar {
    height: 19vw;
  }
  .arrow {
    height: 3.5vw;
  }
  @media only screen and (max-width: 1024px) {
    margin: auto;
    .arrow {
      height: 3rem;
      padding: 1rem;
    }
    .avatar {
      height: 30vh;
    }
  }
`;
