import styled from "styled-components";

export const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(20px);
  z-index: 3;
  display: flex;
  .overlay-layout {
    height: 82%;
    width: 85%;
    margin: 2rem auto 4rem auto;
  }
  .cancel-wrapper {
    display: flex;
    .cancel-icon {
      margin-left: auto;
      height: fit-content;
    }
  }
  @media only screen and (max-width: 1440px) {
    .cancel-wrapper {
      .cancel-icon {
        height: 3rem;
        padding-right: 1rem;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    .overlay-layout {
      width: 95%;
      height: 100%;
      .cancel-wrapper {
        height: 10%;
      }
    }
    .cancel-icon {
      height: 3rem;
      padding-right: 0.5rem;
      margin-top: auto;
    }
  }
`;

export const CollectionWrapper = styled.div`
  border-radius: 10px;
  margin: 0 1rem;
  flex: 2;
  display: flex;
  flex-direction: column;
  .collection-header {
    display: flex;
    padding: 0.8rem 1rem;
    .title {
      width: 25%;
      font-family: poppins-light;
      color: #cacaca;
      margin: auto 0;
    }
    .sub-title-wrapper {
      .sub-title {
        display: inline-block;
        padding: 0.2rem 1.15rem;
        margin: 0 1rem;
        cursor: pointer;
         border-radius: 10px;
        &.active{
          background: rgba(255, 255, 255, 0.42);
        }
      }
    }
  }
  .collection-content {
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: rgba(255, 255, 255, 0.12);
    height: 100%;    border-radius: ${(props) =>
      props.isMobile === false && "10px;"}

    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;
    padding-bottom: 1.5rem;
    .metaverse-name {
      padding: 1vw;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
    .collection-filter {
      font-size: 1vw;
      padding: 1.5rem 1rem 1rem 1rem;
      display: flex;
      .filters {
        display: flex;
        margin: 0 1rem;
        .filter-label {
          margin: auto;
          font-family: poppins-light;
        }
      }
    }
    .wrapped-items {
      display: flex;
      flex-wrap: wrap;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 24px;
      grid-column-gap: 26px;
      padding: 1.5rem;
      .item {
        height: 7.5vw;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    padding-top: 1rem;
    .collection-header {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      .sub-title-wrapper {
        display: flex;
        font-size: 1rem;
        .sub-title {
          margin: 0 1rem 0 0;
        }
      }
    }
    .collection-content {
      .metaverse-name {
        font-size: 1rem;
      }
      .collection-filter {
        display: flex;
        font-size: 1rem;
        padding: 0.8rem 1rem;
        font-size: 14px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-row-gap: 24px;
        grid-column-gap: 14px;
        .filters {
          margin: 0;
          flex-direction: column;
          .filter-label {
            margin: 0;
            margin-bottom: 0.5rem;
          }
        }
      }
      .wrapped-items {
        grid-row-gap: 24px;
        grid-column-gap: 27px;
        padding: 1.5rem;
        .item {
          height: 4.5rem;
        }
      }
    }
  }
`;
export const AvatarWrapper = styled.div`
  display: flex;
  grid-row-gap: 24px;
  grid-column-gap: 2rem;
  padding: 1.5rem;
  flex-wrap: wrap;
  .avatar-back {
    background: white;
    border-radius: 15px;
    position: relative;
    .avatar {
      height: 17vw;
    }

    &.active {
      box-shadow: 0 0 0 5px #a867fb;
      .icon {
        position: absolute;
        bottom: 4px;
        right: 4px;
        height: 1.5rem;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    .avatar-back {
      .avatar {
        height: 15rem;
      }
    }
  }
`;
export const LandWrapper = styled.div`
  display: flex;
  grid-row-gap: 24px;
  grid-column-gap: 2rem;
  padding: 1.5rem;
  flex-wrap: wrap;
  .land-back {
    .land {
      height: 17vw;
      border-radius: 10px;
    }
  }
  @media only screen and (max-width: 1024px) {
    .land-back {
      .land {
        height: 18rem;
      }
    }
  }
  @media only screen and (max-width: 450px) {
    justify-content: center;
  }
`;
export const BackpackWrapper = styled.div`
  border-radius: 10px;
  margin: 0 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  .backpack-header {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    .text {
      color: #cacaca;
      font-family: poppins-light;
    }
  }
  .backpack-content {
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: rgba(255, 255, 255, 0.12);
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 1.5rem;
    border-radius: ${(props) => props.isMobile === false && "10px;"}
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    .backpack-info {
      padding: 1.5rem 1rem;
      text-align: center;
      font-family: poppins-light;
    }
    .wrapped-items {
      display: flex;
      flex-wrap: wrap;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 24px;
      grid-column-gap: 24px;
      padding: 1.5rem;
      justify-content: center;
      .item {
        height: 8vw;
      }
    }
  }
  @media only screen and (max-width: 1440px) {
    .backpack-content {
      .wrapped-items {
        .item {
          height: 8rem;
        }
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    padding-top: 1rem;
    .backpack-content {
      .backpack-info {
        font-size: 14px;
        line-height: 20px;
        padding: 1rem;
      }
      .wrapped-items {
        .item {
          height: 4.5rem;
        }
      }
    }
  }
`;
export const Tabs = styled.div`
  display: flex;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2px;
  font-size: 14px;
  .tab {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-family: poppins-light;
    width: 50%;
    padding: 0.7rem 0;
    text-align: center;
    .item-count {
      padding: 0.1rem 0.2rem 0 0.2rem;
      margin: 0 0.4rem;
      font-weight: 600;
      background: white;
      display: inline-block;
      color: black;
      border-radius: 45%;
      font-size: 10px;
    }
    &.active {
      background: rgba(255, 255, 255, 0.25);
    }
    &.inactive {
      background: rgba(255, 255, 255, 0.15);
    }
  }
`;
export const BagWrapper = styled.div`
  display: flex;
  height: 100%;
  // font-size: 21px;
  font-size: 1.1vw;
  @media only screen and (max-width: 1024px) {
    height: 80%;
    display: flex;
    flex-direction: column;
  }
`;
