import React, { useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "../container/header";
import HistoryPage from "../container/historyPage";
import HomePage from "../container/homePage";
import { MetaverseIframe } from "../MetaverseIframe";
import { useIsMobile } from "../utils/utils";
function HomePageLayout() {
  const isMobile = useIsMobile();
  const itemsState = useSelector((state) => state.inventory);
  const [showBag, setShowBag] = useState(false);
  const handleCloseBag = () => {
    setShowBag(false);
  };
  const handleOpenBag = () => {
    setShowBag(true);
  };
  return (
    <Router>
      <Header
        isMobile={isMobile}
        onOpen={handleOpenBag}
        itemsState={itemsState}
      />

      <Switch>
        <Route exact path="/decentraland" component={MetaverseIframe} />
        <Route
          exact
          path="/app/home"
          render={(props) => (
            <HomePage showBag={showBag} handleCloseBag={handleCloseBag} />
          )}
        />
        <Route
          exact
          path="/app/history"
          render={(props) => (
            <HistoryPage showBag={showBag} handleCloseBag={handleCloseBag} />
          )}
        />
      </Switch>
    </Router>
  );
}

export default HomePageLayout;
