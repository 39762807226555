import React from "react";
import { ItemWrapper } from "./style";
import AddIcon from "../../assets/icons/plus_icon.svg";
import RemoveIcon from "../../assets/icons/Substract Icon.svg";

export default function BagItem({ image, icon, onClick }) {
  return (
    <ItemWrapper>
      <img className="item" src={image} alt="bagItem"></img>
      {icon ? (
        <img className="icon" src={AddIcon} onClick={onClick} />
      ) : (
        <img className="icon" src={RemoveIcon} onClick={onClick} />
      )}
    </ItemWrapper>
  );
}
