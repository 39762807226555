/* eslint-disable prettier/prettier */
import styled from "styled-components";
import { theme } from "../../utils/themeVariables";

export const StyledButton = styled.button`
  padding: ${(props) => (props.isMobile ? "10px 17.5px" : "11px 37.5px")};
  border: none;
  width: fit-content;
  background: rgb(1, 133, 255);
  cursor: pointer;
  border: 2px ${(props) => (props.isMobile ? "solid" : "none")}
    rgba(255, 255, 255, 0.4);
  border-radius: 50px;
  &:hover {
    border: 2px solid rgba(255, 255, 255, 0.4);
  }
  background: linear-gradient(
    115.53deg,
    ${theme["btn-start-color"]} -38.2%,
    ${theme["btn-end-color"]} 110.84%
  );
  margin: 0 auto;
  font-size: 23px;
  line-height: 36px;
  @media only screen and (max-width: 1400px) {
    font-size: 18px;
    line-height: 20px;
  }
  @media only screen and (max-width: 1024px) {
    font-size: 14px;
    line-height: 21px;
  }
`;
