import styled from "styled-components";

export const HeaderWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100vw;
  margin-top: 2rem;
  .header-row {
    display: flex;
    justify-content: space-between;
    margin: 0 8vw;
    .logo {
      height: ${(props) => (props.isMobile ? "34px" : "3vw")};
    }
    .history {
      margin: auto 1.5vw;
      font-size: 1.3vw;
      padding: 0.5rem 2rem;
      border-radius: 10px;
      &.active {
        background: rgba(255, 255, 255, 0.12);
      }
    }
    .bag-wrapper {
      display: block;
      margin-left: 2vw;
      background: rgba(255, 255, 255, 0.12);
      border-radius: 50%;
      padding: 0.6rem 0.6rem 0.3rem 0.6rem;
      border: 2px solid rgba(255, 255, 255, 0.4);
      position: relative;
      .bag-icon {
        margin: auto;
        height: 3vw;
      }
      .item-count {
        position: absolute;
        bottom: -0.5rem;
        right: -0.5rem;
        border-radius: 50%;
        background: #a867fb;
        height: 50%;
        width: 50%;
        font-weight: bold;
        font-size: 0.7vw;
        line-height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
      }
    }
  }
  .left-arrow {
    height: 3.5vw;
    padding-top: 1vw;
    padding-left: 8vw;
  }
  .mobile-banner {
    margin-top: 3rem;
    object-fit: contain;
    width: 100%;
    border-radius: 0.5rem;
  }
  @media only screen and (max-width: 1024px) {
    .logo {
      align-self: center;
    }
    .header-row {
      .bag-wrapper {
        .bag-icon {
          height: 2.5rem;
        }
        .item-count {
          font-size: 10px;
        }
      }
    }
    .left-arrow {
      height: 12vw;
      padding-top: 5vw;
    }
  }
`;
