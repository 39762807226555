const actions = {
  ADD_ITEM_BEGIN: "ADD_ITEM_BEGIN",
  ADD_ITEM_SUCCESS: "ADD_ITEM_SUCCESS",
  ADD_ITEM_ERR: "ADD_ITEM_ERR",

  REMOVE_ITEM_BEGIN: "REMOVE_ITEM_BEGIN",
  REMOVE_ITEM_SUCCESS: "REMOVE_ITEM_SUCCESS",
  REMOVE_ITEM_ERR: "REMOVE_ITEM_ERR",

  CHANGE_AVATAR_BEGIN: "CHANGE_AVATAR_BEGIN",
  CHANGE_AVATAR_SUCCESS: "CHANGE_AVATAR_SUCCESS",
  CHANGE_AVATAR_ERR: "CHANGE_AVATAR_ERR",

  addItemBegin: () => {
    return {
      type: actions.ADD_ITEM_BEGIN,
    };
  },

  addItemSuccess: (data) => {
    return {
      type: actions.ADD_ITEM_SUCCESS,
      data,
    };
  },

  addItemErr: (err) => {
    return {
      type: actions.ADD_ITEM_ERR,
      err,
    };
  },

  removeItemBegin: () => {
    return {
      type: actions.REMOVE_ITEM_BEGIN,
    };
  },

  removeItemSuccess: (data) => {
    return {
      type: actions.REMOVE_ITEM_SUCCESS,
      data,
    };
  },

  removeItemErr: (err) => {
    return {
      type: actions.REMOVE_ITEM_ERR,
      err,
    };
  },

  changeAvatarBegin: () => {
    return {
      type: actions.CHANGE_AVATAR_BEGIN,
    };
  },

  changeAvatarSuccess: (data) => {
    return {
      type: actions.CHANGE_AVATAR_SUCCESS,
      data,
    };
  },

  changeAvatarErr: (err) => {
    return {
      type: actions.CHANGE_AVATAR_ERR,
      err,
    };
  },
};

export default actions;
