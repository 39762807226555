import actions from "./actions";
const {
  addItemBegin,
  addItemSuccess,
  addItemErr,
  removeItemBegin,
  removeItemSuccess,
  removeItemErr,

  changeAvatarBegin,
  changeAvatarSuccess,
  changeAvatarErr,
} = actions;

const addItemToInventory = (data) => {
  return async (dispatch) => {
    try {
      dispatch(addItemBegin());
      dispatch(addItemSuccess(data));
    } catch (err) {
      dispatch(addItemErr(err));
    }
  };
};

const removeItemFromInventory = (data) => {
  return async (dispatch) => {
    try {
      dispatch(removeItemBegin());
      dispatch(removeItemSuccess(data));
    } catch (err) {
      dispatch(removeItemErr(err));
    }
  };
};

const changeAvatar = (data) => {
  return async (dispatch) => {
    try {
      dispatch(changeAvatarBegin());
      dispatch(changeAvatarSuccess(data));
    } catch (err) {
      dispatch(changeAvatarErr(err));
    }
  };
};

export { addItemToInventory, removeItemFromInventory, changeAvatar };
