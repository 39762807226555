import React from "react";
import { Link } from "react-router-dom";
import "./sidebar.css";
import HamBurgerIcon from "../../assets/icons/Hamburger.png";
import HistoryIcon from "../../assets/icons/history_Circle.png";
import CloseIcon from "../../assets/icons/close button.png";
import BagIcon from "../../assets/icons/BagWithCircle.png";

function SideBar({ onSideBar, showSidebar, showBag }) {
  const handleBagOpen = () => {
    showBag();
    showSidebar();
  };
  return (
    <>
      <div>
        <div className="navbar">
          <div className="menu-bars">
            <img src={HamBurgerIcon} onClick={onSideBar} />
          </div>
        </div>
        <nav className={showSidebar ? "nav-menu active" : "nav-menu"}>
          <ul className="nav-menu-items" onClick={onSideBar}>
            <li className="nav-text">
              <Link to="#">
                <img
                  className="sidebar-icons"
                  src={CloseIcon}
                  onClick={onSideBar}
                />
              </Link>
            </li>
            <li key={1} className="nav-text">
              <Link to="#">
                <img
                  className="sidebar-icons"
                  src={BagIcon}
                  onClick={handleBagOpen}
                />
              </Link>
              Bag
            </li>
            <li key={2} className="nav-text">
              <Link to={"/app/history"}>
                <img
                  className="sidebar-icons"
                  src={HistoryIcon}
                  onClick={onSideBar}
                />
              </Link>
              History
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default SideBar;
