import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { MapWrapper, MapWrapperForJumpIn, JumpButtonWrap } from "./style";

import { BackpackWrapper, BagWrapper, Overlay } from "../Inventory/style";

import Cross from "../../assets/icons/close button.png";
import HeartFill from "../../assets/icons/Heart_Fill.png";
import ZoomIn from "../../assets/icons/Plus.png";
import ZoomOut from "../../assets/icons/Minus.png";
import Map from "../../assets/img/map.png";

import { renderThumb } from "../../utils/constant";
import BagItem from "../../components/bagItem/BagItem";
import Empty from "../../components/Empty/Empty";
import { useIsMobile } from "../../utils/utils";
import Button from "../../components/button/buttons";

function JumpInWithInventory({ onCancel, onRemoveItemfromBag }) {
  const history = useHistory();
  const itemsState = useSelector((state) => state.inventory);
  const isMobile = useIsMobile();
  const BackPackComponent = () => {
    return (
      <div className="backpack-content">
        <div className="backpack-info">Assets to teleport to decentraland</div>
        <Scrollbars autoHide renderThumbVertical={renderThumb}>
          {itemsState.backpackItems.length > 0 ? (
            <div className="justify-center">
              <div className="wrapped-items">
                {itemsState.backpackItems?.map((item) => {
                  return (
                    <BagItem
                      key={item.id}
                      className="modal-item"
                      image={item.path}
                      icon={false}
                      onClick={() => onRemoveItemfromBag(item)}
                    ></BagItem>
                  );
                })}
              </div>
            </div>
          ) : (
            <Empty>No item's in backpack</Empty>
          )}
        </Scrollbars>
        {!isMobile && (
          <JumpButtonWrap>
            <Button onClick={() => history.push("/decentraland")}>
              Jump In
            </Button>
          </JumpButtonWrap>
        )}
      </div>
    );
  };

  return (
    <Overlay>
      <div className="overlay-layout">
        <div className="cancel-wrapper" onClick={onCancel}>
          <img className="cancel-icon" src={Cross} onClick={onCancel} />
        </div>
        <BagWrapper>
          <MapWrapperForJumpIn>
            <div className="collection-header">
              <span className="title">Select your destination to jump</span>
            </div>
            <MapWrapper className="map">
              <div className="control-wrapper">
                <img src={Map} className="img" />
                <div className="controls">
                  <div>
                    <img src={HeartFill}></img>
                  </div>
                  <ul className="zoomInOut">
                    <li>
                      <img src={ZoomIn}></img>
                    </li>
                    <li>
                      <img src={ZoomOut}></img>
                    </li>
                  </ul>
                </div>
              </div>
              {isMobile && (
                <div className="jump-in">
                  <Button onClick={() => history.push("/decentraland")}>
                    Jump In
                  </Button>
                </div>
              )}
            </MapWrapper>
          </MapWrapperForJumpIn>
          {!isMobile && (
            <BackpackWrapper>
              <div className="backpack-header">
                <span className="text">Backpack</span>
                <span>
                  {itemsState?.backpackItems?.length}/
                  {itemsState?.collections?.length}
                </span>
              </div>
              <BackPackComponent />
            </BackpackWrapper>
          )}
        </BagWrapper>
      </div>
    </Overlay>
  );
}

export default JumpInWithInventory;
