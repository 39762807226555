import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { HeaderWrapper } from "./style";
import metaPortLogo from "../../assets/icons/metaport Logo.png";
import BagIcon from "../../assets/icons/Bag_icon.png";
import LeftArrow from "../../assets/icons/L_arrow.png";
import SideBar from "../../components/sideBar/SideBar";

function Header({ isMobile, onOpen }) {
  const history = useHistory();
  const itemsState = useSelector((state) => state.inventory);
  const [showBar, setSidebar] = useState(false);
  const handleShowSidebar = () => setSidebar(!showBar);
  return (
    <>
      {window.location.pathname !== "/decentraland" && (
        <HeaderWrapper isMobile={isMobile}>
          <div className="header-row">
            <img
              className="logo pointer"
              src={metaPortLogo}
              onClick={() => history.push("/app/home")}
            ></img>
            <section className="d-flex" id="sliderID">
              {isMobile ? (
                <SideBar
                  onSideBar={handleShowSidebar}
                  showSidebar={showBar}
                  showBag={onOpen}
                />
              ) : (
                <>
                  <div
                    className={`history ${
                      window.location.pathname === "/app/history"
                        ? "active"
                        : "inactive"
                    }  pointer`}
                    onClick={() => history.push("/app/history")}
                  >
                    History
                  </div>
                  <span className="bag-wrapper pointer">
                    <img
                      className="bag-icon"
                      src={BagIcon}
                      onClick={onOpen}
                    ></img>
                    <div className="item-count">
                      {itemsState?.backpackItems.length}/
                      {itemsState?.collections.length}
                    </div>
                  </span>
                </>
              )}
            </section>
          </div>
          {history.location.pathname === "/app/history" && (
            <img
              className="left-arrow pointer"
              src={LeftArrow}
              onClick={() => history.push("/app/home")}
            ></img>
          )}
        </HeaderWrapper>
      )}
    </>
  );
}

export default Header;
