import styled from "styled-components";

export const HistoryWrapper = styled.div`
  width: 75vw;
  top: 0;
  height: 54vh;
  bottom: 0;
  margin: 0 auto;
  overflow: hidden;
  z-index: 3;
  img {
    pointer-events: none;
    user-select: none;
    object-fit: fill !important;
    height: 25vw;
  }
  @media only screen and (max-width: 1024px) {
    width: 100vw;
    img {
      height: 60vw;
    }
  }
`;

export const JumpInButton = styled.div`
  top: 75%;
  position: absolute;
  z-index: 3;

  @media only screen and (max-width: 1024px) {
    top: 65%;
    .arrow-wrapper {
      img {
        height: 3rem;
        background: black;
        border-radius: 50%;
      }
    }
    .slide-btn {
      bottom: auto;
    }
  }
  @media only screen and (max-width: 768px) {
    top: 58%;
  }
`;

export const SliderImageWrapper = styled.div`
  position: relative;
  .meta-data {
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    margin-bottom: 1vw;

    justify-content: space-between;
    font-size: 1.1vw;
    .meta-name {
      padding-left: 1vw;
    }
    .travel-time {
      padding-right: 1vw;
      margin-top: auto;
    }
  }
  @media only screen and (max-width: 1024px) {
    .meta-data {
      font-size: 12px;
    }
  }
`;

export const AvatarWrapperForHistory = styled.div`
  z-index: 3;
  position: absolute;
  bottom: 10%;
  right: 10%;
  .avatar {
    height: 19vw;
  }
  .arrow {
    height: 3.5vw;
  }
  @media only screen and (max-width: 1024px) {
    bottom: 0;
    right: auto;
    margin: auto;
    .arrow {
      height: 3rem;
      padding: 1rem;
    }
    .avatar {
      height: 15rem;
    }
  }
`;
