import React from "react";
import { StyledButton } from "./style.js";

function Button({ children, isMobile, onClick, style }) {
  return (
    <StyledButton isMobile={isMobile} onClick={onClick} style={style}>
      {children}
    </StyledButton>
  );
}

export default Button;
