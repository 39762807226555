import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import InventoryReducer from "./inventory/reducers";

const persistConfig = {
  key: "root",
  storage,
};
const appReducers = combineReducers({
  inventory: InventoryReducer,
});

const rootReducers = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === "LOGOUT_SUCCESS") {
    state = undefined; // eslint-disable-line no-param-reassign
  }

  return appReducers(state, action);
};

export default persistReducer(persistConfig, rootReducers);
