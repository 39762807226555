import React, { useState } from "react";
import { config } from "react-spring";
import { useDispatch, useSelector } from "react-redux";

import { useSwipeable } from "react-swipeable";
import {
  AvatarWrapperForHistory,
  JumpInButton,
  SliderImageWrapper,
} from "./style";
import Floor from "../../assets/img/floor.jpg";
import { useIsMobile } from "../../utils/utils";
import LeftArrow from "../../assets/icons/L_arrow.png";
import RightArrow from "../../assets/icons/R_arrow.png";
import Button from "../../components/button/buttons";
import { SliderImages } from "../../utils/sliderImages";
import Inventory from "../Inventory/Inventory";
import {
  addItemToInventory,
  removeItemFromInventory,
  changeAvatar,
} from "../../redux/inventory/actionCreator";
import { MainWrapper } from "../styled";
import JumpInWithInventory from "../modals/JumpInWithInventory";
import Slider from "../../components/slider/Slider";

export const HistoryPage = ({ showBag, handleCloseBag }) => {
  const [state, setState] = useState({
    goToSlide: 0,
    offsetRadius: 2,
    showNavigation: true,
    config: config.gentle,
  });
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const SliderImagesForHistory = [];
  const avatars = useSelector((state) => state.inventory.avatars);
  const currentAvatar = useSelector((state) => state.inventory.currentAvatar);

  const [jumpInModal, setJumpInModal] = useState({
    metaData: null,
    visible: false,
  });
  const { data, visible } = jumpInModal;
  const cancelJumpInModal = () => {
    setJumpInModal({
      visible: false,
      data: null,
    });
  };

  SliderImages.map((item) => {
    SliderImagesForHistory.push({
      key: item.id,
      content: (
        <SliderImageWrapper onClick={() => setState({ goToSlide: item.id })}>
          <img className="slider-img" src={item.path}></img>
          <div className="meta-data">
            <div className="meta-name">
              {item.metaInfo}
              <br />
              {item.name}
            </div>
            <div className="travel-time">{item.visited}</div>
          </div>
        </SliderImageWrapper>
      ),
    });
  });
  const arrowLeftAvatar = () => {
    const updatedIndex =
      avatars.indexOf(currentAvatar) > 0
        ? avatars.indexOf(currentAvatar) - 1
        : avatars.length - 1;
    dispatch(changeAvatar(avatars[updatedIndex]));
  };

  const arrowRightAvatar = () => {
    const updatedIndex =
      avatars.indexOf(currentAvatar) < 2
        ? avatars.indexOf(currentAvatar) + 1
        : 0;
    dispatch(changeAvatar(avatars[updatedIndex]));
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => setState({ goToSlide: state.goToSlide + 1 }),
    onSwipedRight: () => setState({ goToSlide: state.goToSlide - 1 }),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });
  const addItemToBag = (item) => {
    dispatch(addItemToInventory(item));
  };
  const removeItemFromBag = (item) => {
    dispatch(removeItemFromInventory(item));
  };
  const handleSwipeLeft = () => {
    setState({ ...state, goToSlide: state.goToSlide + 1 });
  };
  const handleSwipeRight = () => {
    setState({ ...state, goToSlide: state.goToSlide - 1 });
  };
  console.log(state);
  return (
    <MainWrapper>
      <div className="bg"></div>
      <Slider
        slides={SliderImagesForHistory}
        swipeLeft={handleSwipeLeft}
        swipeRight={handleSwipeRight}
        currentSlide={state.goToSlide}
      />
      <JumpInButton>
        <Button
          className="slide-button"
          isMobile={isMobile}
          onClick={() =>
            setJumpInModal({
              visible: true,
            })
          }
        >
          Jump in
        </Button>
      </JumpInButton>
      <img className="floor" src={Floor}></img>
      <AvatarWrapperForHistory>
        <img
          className="arrow pointer"
          onClick={arrowLeftAvatar}
          src={LeftArrow}
        ></img>
        <img className="avatar" src={currentAvatar.path}></img>
        <img
          className="arrow pointer"
          onClick={arrowRightAvatar}
          src={RightArrow}
        ></img>
      </AvatarWrapperForHistory>
      {visible && (
        <JumpInWithInventory
          data={data}
          onCancel={cancelJumpInModal}
          onRemoveItemfromBag={removeItemFromBag}
        />
      )}
      {showBag && (
        <Inventory
          onCancel={handleCloseBag}
          onAddItemToBag={addItemToBag}
          onRemoveItemfromBag={removeItemFromBag}
        />
      )}
    </MainWrapper>
  );
};

export default HistoryPage;
