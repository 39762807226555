import actions from "./actions";

import { AvatarImages } from "../../utils/avatarImages";
import { BagItems } from "../../utils/bagItems";

const {
  ADD_ITEM_BEGIN,
  ADD_ITEM_SUCCESS,
  ADD_ITEM_ERR,
  REMOVE_ITEM_BEGIN,
  REMOVE_ITEM_SUCCESS,
  REMOVE_ITEM_ERR,

  CHANGE_AVATAR_BEGIN,
  CHANGE_AVATAR_SUCCESS,
  CHANGE_AVATAR_ERR,
} = actions;

const initState = {
  initialItems: BagItems,
  collections: BagItems,
  backpackItems: [],
  avatars: AvatarImages,
  currentAvatar: AvatarImages[0],
  loading: false,
  error: null,
};

const InventoryReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ADD_ITEM_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ADD_ITEM_SUCCESS: {
      const updatedBag = state.collections.filter(
        (value) => value.id !== data.id
      );
      console.log(data);
      return {
        ...state,
        loading: false,
        collections: updatedBag,
        backpackItems: [...state.backpackItems, data],
      };
    }

    case ADD_ITEM_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case REMOVE_ITEM_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case REMOVE_ITEM_SUCCESS: {
      const updatedBag = state.backpackItems.filter(
        (value) => value.id !== data.id
      );
      return {
        ...state,
        collections: [...state.collections, data],
        backpackItems: updatedBag,
        loading: false,
      };
    }
    case REMOVE_ITEM_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CHANGE_AVATAR_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CHANGE_AVATAR_SUCCESS: {
      return {
        ...state,
        loading: false,
        currentAvatar: data,
      };
    }

    case CHANGE_AVATAR_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};
export default InventoryReducer;
