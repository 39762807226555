export const minWidth = window.innerWidth < 1024;

export const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 10,
    backgroundColor: "#F1F2F6",
    height: "220px",
  };
  return <div style={{ ...style, ...thumbStyle }} props={props} />;
};
export const options = ["Decentraland", "The SandBox"];
export const collections = [
  "Atari Launch",
  "Alison wonderland",
  "Bored Ape",
  "Snoopdoog Collection",
  "Falledn angles",
];
export const sortConst = ["Newest", "Oldest", "Recommanded"];
export const filterDashboardConst = ["Favourites", "Popular", "Newest", "All"];
