import styled from "styled-components";

export const EmptyWrapper = styled.div`
  display: flex;
  height: 65%;
  justify-content: center;
  align-items: center;
  .empty-text {
    font-size: 1.1vw;
  }
  @media only screen and (max-width: 825px) {
    .empty-text {
      font-size: 4vw;
    }
  }
`;
