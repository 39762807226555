import React from "react";
import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";
import { useSwipeable } from "react-swipeable";
import { SpringSliderWrapper } from "../../container/styled";

function Slider({ swipeLeft, swipeRight, slides, currentSlide, animateC }) {
  const handlers = useSwipeable({
    onSwipedLeft: () => swipeLeft,
    onSwipedRight: () => swipeRight,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });
  return (
    <SpringSliderWrapper {...handlers}>
      <Carousel
        slides={slides}
        goToSlide={currentSlide}
        offsetRadius={2}
        showNavigation={true}
        animationConfig={animateC}
      />
    </SpringSliderWrapper>
  );
}

export default Slider;
