import { useEffect } from "react";
import MetaMaskOnboarding from "@metamask/onboarding";
import { useWeb3React } from "@web3-react/core";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { setItem } from "./utils/localStorageControl";
import { fortmatic, walletconnect } from "./web3/connectors";
import HomePageLayout from "./routes/HomePageLayout";
import { LandingPage } from "./container/landingPage";
import { MetaverseIframe } from "./MetaverseIframe";

function App() {
  const { account, activate } = useWeb3React();
  if (MetaMaskOnboarding.isMetaMaskInstalled()) {
    // eslint-disable-next-line prettier/prettier
    window.ethereum.on("accountsChanged", (accounts) => {
      setItem("account", accounts[0]);
      if (!accounts[0]) {
        localStorage.clear();
      }
    });
  }

  useEffect(() => {
    if (account && window.location.pathname === "/") {
      window.location.replace("/app/home");
    }
  }, [account]);

  const connectWC = async () => {
    await activate(walletconnect);
  };
  const connectFortmatic = async () => {
    await activate(fortmatic);
  };

  return (
    <Router>
      <Switch>
        <Route path="/decentraland" component={MetaverseIframe} />
        <Route path="/app" render={() => <HomePageLayout />} />
        <Route
          path="/"
          render={() => (
            <LandingPage
              connectWC={connectWC}
              connectFortmatic={connectFortmatic}
            />
          )}
        />
      </Switch>
    </Router>
  );
}

export default App;
