import shirtBlack from "../assets/img/shirtblack.png";
import shoesbird from "../assets/img/shoesbird.png";
import shoesgreen from "../assets/img/shoesgreen.png";
import shoesorange from "../assets/img/shoesorange.png";
import shoesyellow from "../assets/img/shoesyellow.png";
import shortspink from "../assets/img/shortspink.png";
import sunglass from "../assets/img/sunglass.png";

export const BagItems = [
  { id: 1, name: "shirtBlack", path: shirtBlack },
  { id: 2, name: "shoesbird", path: shoesbird },
  { id: 3, name: "shoesgreen", path: shoesgreen },
  { id: 4, name: "shoesorange", path: shoesorange },
  { id: 5, name: "shoesyellow", path: shoesyellow },
  { id: 6, name: "shortspink", path: shortspink },
  { id: 7, name: "sunglass", path: sunglass },
];
