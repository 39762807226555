import styled from "styled-components";

export const LandingPageContanier = styled.div`
  height: 100vh;
  margin: 0px 7%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .content,
  .meta-mask-card {
    display: flex;
  }
  .content {
    flex: 2.5;
    flex-direction: column;
    .logo {
      padding-bottom: 1.6vw;
      height: 3vw;
      width: fit-content;
    }
    .metaverse-title {
      display: block;
      font-size: 3vw;
      line-height: 5.8vw;
    }
    .metaverse-subtitle {
      font-family: poppins-light;
      font-size: 1.3vw;
      font-weight: normal;
      line-height: 2.5vw;
      padding-left: 5px;
    }
  }
  .meta-mask-card {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 465px;
    height: 65vh;
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    border: 1px solid darkgray;
    .wallets-wrapper {
      height: 40vh;
      margin: 0 6.5vh;
      .wallet {
        height: 9vh;
        margin-bottom: 1.4vh;
        background: rgba(255, 255, 255, 0.29);
        border-radius: 10px;
        position: relative;
        display: flex;
        cursor: pointer;
        img {
          height: 5vh;
          margin: auto 2.1vh;
        }
        .icon-wc {
          height: 4.5vh;
          margin: auto 1.5vh;
        }
        span {
          font-size: 1.3vw;
          position: absolute;
          top: 28%;
          left: 32%;
        }
      }
    }
    .mix-icon {
      margin: 0 auto;
      height: 10vw;
      img {
        height: inherit;
      }
    }

    .metamask-msg {
      text-align: center;
      font-style: normal;
      font-size: 1.4vw;
      line-height: 2.5vw;
    }

    button {
      margin: 0 auto;
      font-size: 1.4vw;
      padding: 0.7vw 3.2vw;
    }
  }
  .bgVideo {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
  @media only screen and (max-width: 1024px) {
    .meta-mask-card {
      flex: 4;
      .metamask-msg {
        text-align: center;
        font-style: normal;
        font-size: 2.4vw;
        line-height: 2.5vw;
      }
      .wallets-wrapper {
        .wallet {
          span {
            font-size: 20px;
            position: absolute;
            top: 33%;
            left: 40%;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    height: 83vh;
    margin: 0px 1.5rem;
    flex-direction: column;
    justify-content: center;
    .content {
      flex: inherit;
      margin-bottom: 35px;
      align-self: flex-start;
      .metaverse-title {
        font-size: 24px;
        line-height: 40px;
      }
      .metaverse-subtitle {
        font-size: 14px;
        line-height: 24px;
        padding-left: 0px;
      }
    }
    .meta-mask-card {
      flex: inherit;
      width: 100%;
      height: 40vh;
      .wallets-wrapper {
        height: auto;
        margin: 0 4vh;
        .wallet {
          height: 7vh;
          margin-bottom: 1.4vh;
          background: rgba(255, 255, 255, 0.29);
          border-radius: 10px;
          position: relative;
          display: flex;
          img {
            height: 4vh;
            margin: auto 2vh;
          }
          .icon-wc {
            height: 3.5vh;
            margin: auto 1.3vh;
          }
          span {
            font-size: 16px;
            position: absolute;
            top: 28%;
            left: 32%;
          }
        }
      }
      .metamask-msg {
        font-size: 16px;
        line-height: 24px;
      }
      .meta-mask-logo {
        height: 120px;
      }
      .mix-icon {
        height: 30vw;
      }
      button {
        font-size: 14px;
        line-height: 21px;
        padding: 9px 20px;
      }
    }
  }
`;
