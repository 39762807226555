import React, { useState } from "react";
import { config } from "react-spring";
import { useDispatch, useSelector } from "react-redux";
import { useWeb3React } from "@web3-react/core";

import { AvatarWrapper, BottomCrontrolWrapper } from "./style";
import Inventory from "../Inventory/Inventory";
import LeftArrow from "../../assets/icons/L_arrow.png";
import RightArrow from "../../assets/icons/R_arrow.png";
import Floor from "../../assets/img/floor.jpg";
import Button from "../../components/button/buttons";
import { useIsMobile } from "../../utils/utils";
import { SliderImages } from "../../utils/sliderImages";
import {
  addItemToInventory,
  changeAvatar,
  removeItemFromInventory,
} from "../../redux/inventory/actionCreator";
import { JumpInButton, SliderImageWrapper } from "../historyPage/style";
import Dropdown from "../../components/dropdown/Dropdown";
import { filterDashboardConst } from "../../utils/constant";
import { MainWrapper } from "../styled";
import JumpInWithInventory from "../modals/JumpInWithInventory";
import Slider from "../../components/slider/Slider";

function HomePage({ showBag, handleCloseBag }) {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const avatars = useSelector((state) => state.inventory.avatars);
  const currentAvatar = useSelector((state) => state.inventory.currentAvatar);
  const [jumpInModal, setJumInModal] = useState({
    metaData: null,
    visible: false,
  });
  const { data, visible } = jumpInModal;

  const [state, setState] = useState({
    goToSlide: 0,
    offsetRadius: 2,
    showNavigation: true,
    config: config.gentle,
  });

  const SliderImagesForHome = [];
  SliderImages.map((item) => {
    SliderImagesForHome.push({
      key: item.id,
      content: (
        <SliderImageWrapper
          onClick={() => setState({ ...state, goToSlide: item.id })}
        >
          <img className="slider-img" src={item.path}></img>
        </SliderImageWrapper>
      ),
    });
  });

  const handleSwipeLeft = () => {
    setState({ ...state, goToSlide: state.goToSlide + 1 });
  };
  const handleSwipeRight = () => {
    setState({ ...state, goToSlide: state.goToSlide - 1 });
  };

  const addItemToBag = (item) => {
    dispatch(addItemToInventory(item));
  };
  const removeItemFromBag = (item) => {
    dispatch(removeItemFromInventory(item));
  };

  const cancelJumpInModal = () => {
    setJumInModal({
      visible: false,
      data: null,
    });
  };

  const arrowLeftAvatar = () => {
    const updatedIndex =
      avatars.indexOf(currentAvatar) > 0
        ? avatars.indexOf(currentAvatar) - 1
        : avatars.length - 1;
    dispatch(changeAvatar(avatars[updatedIndex]));
  };

  const arrowRightAvatar = () => {
    const updatedIndex =
      avatars.indexOf(currentAvatar) < 2
        ? avatars.indexOf(currentAvatar) + 1
        : 0;
    dispatch(changeAvatar(avatars[updatedIndex]));
  };

  const [dropDownState, setDropdownState] = useState({
    filter: "All",
  });
  const { filter } = dropDownState;

  return (
    <MainWrapper background={SliderImages[state.index]?.path} id="page-wrap">
      <div className="bg"></div>
      <Slider
        slides={SliderImagesForHome}
        swipeLeft={handleSwipeLeft}
        swipeRight={handleSwipeRight}
        currentSlide={state.goToSlide}
      />
      <JumpInButton>
        <Button
          className="slide-button"
          isMobile={isMobile}
          onClick={() => {
            setJumInModal({
              visible: true,
            });
          }}
        >
          Jump in
        </Button>
      </JumpInButton>

      <img className="floor" src={Floor}></img>
      <BottomCrontrolWrapper>
        {!isMobile && (
          <div className="dropdown">
            <Dropdown
              minWidth="8rem"
              direction="up"
              background="darker"
              selected={filter}
              setSelected={(props) =>
                setDropdownState({
                  ...dropDownState,
                  filter: props,
                })
              }
              options={filterDashboardConst}
            />
          </div>
        )}

        <AvatarWrapper>
          <img
            className="arrow pointer"
            onClick={arrowLeftAvatar}
            src={LeftArrow}
          ></img>
          <img className="avatar" src={currentAvatar.path}></img>
          <img
            className="arrow pointer"
            onClick={arrowRightAvatar}
            src={RightArrow}
          ></img>
        </AvatarWrapper>
      </BottomCrontrolWrapper>
      {visible && (
        <JumpInWithInventory
          data={data}
          onCancel={cancelJumpInModal}
          onRemoveItemfromBag={removeItemFromBag}
        />
      )}
      {showBag && (
        <Inventory
          onCancel={handleCloseBag}
          onAddItemToBag={addItemToBag}
          onRemoveItemfromBag={removeItemFromBag}
        />
      )}
    </MainWrapper>
  );
}

export default HomePage;
