import styled from "styled-components";
import { CollectionWrapper } from "../Inventory/style";

export const JumpInModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.8vw 4.5vw;
  .meta-title {
    font-weight: 500;
    font-size: 28px;
    line-height: 45px;
    text-align: center;
  }
  .bag-box {
    height: 28vh;
    padding: 1.5vw;
    background: rgba(255, 255, 255, 0.15);
    margin-bottom: 1.5vw;
    border-radius: 10px;
    .no-item {
      display: flex;
      height: 65%;
      justify-content: center;
      align-items: center;
    }
    .scroll-wrapper {
      height: 12rem;
    }
    .meta-info {
      font-family: poppins-light;
      font-weight: 300;
      font-size: 16px;
      line-height: 20px;
      padding: 0.4rem 0 1.8rem 0rem;
    }
    .item-box {
      display: flex;
      flex-wrap: wrap;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 24px;
      grid-column-gap: 24px;
      .item {
        height: 4.5rem;
      }
    }
  }
  .meta-banner {
    padding-top: 1rem;
    padding-bottom: 1.5rem;
  }
  @media only screen and (max-width: 1400px) {
    padding: 0rem 4.5rem;
    .bag-box {
      width: 23rem;
      align-self: center;
      .scroll-wrapper {
        height: 8rem;
      }
      .item-box {
        .item {
          height: 3rem;
        }
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    padding: 0;
    .bag-box {
      width: auto;
      .item-box {
        grid-row-gap: 16px;
        grid-column-gap: 20px;
      }
    }
  }
`;

export const MapWrapperForJumpIn = styled(CollectionWrapper)`
  .collection-header {
    padding: 1rem;
    .title {
      width: 100%;
      @media only screen and (max-width: 1024px) {
        font-size: 14px;
        text-align: center;
      }
    }
  }
`;
export const JumpButtonWrap = styled.div`
  margin: 0.5rem auto;
`;
export const MapWrapper = styled.div`
  background: rgba(255, 255, 255, 0.12);
  height: 100%;
  display: flex;
  flex-direction: column;
  .control-wrapper {
    overflow: hidden;
    position: relative;
    .img {
      position: absolute;
      z-index: -1;
    }
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
    flex: 1;
    .controls {
      display: flex;
      justify-content: space-between;
      margin: 1rem;
    }

    .zoomInOut {
      flex-direction: column;
      list-style-type: none;
      padding: 0;
      margin: 0;
      li img {
        border: none;
        vertical-align: bottom;
      }
    }
  }
  .jump-in {
    margin: 0rem auto 0.5rem;
  }
`;
export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
`;
