import { useEffect, useState, useRef } from "react";
import { DropdownWrapper } from "./style";
import { listenForOutsideClicks } from "../../utils/utils";
import DropdownIcon from "../../assets/icons/dropdown.png";

function Dropdown({
  minWidth,
  direction,
  selected,
  setSelected,
  options,
  background,
}) {
  const [isActive, setIsActive] = useState(false);
  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);

  useEffect(
    listenForOutsideClicks(listening, setListening, menuRef, setIsActive)
  );

  return (
    <DropdownWrapper
      minWidth={minWidth}
      background={background}
      direction={direction}
    >
      <div
        ref={menuRef}
        className={`dropdown-btn ${background}`}
        onClick={(e) => setIsActive(!isActive)}
      >
        <span className="selected">{selected}</span>
        <img src={DropdownIcon} />
      </div>
      {isActive && (
        <div className="dropdown-content">
          {options.map((option) => (
            <div
              onClick={(e) => {
                setSelected(option);
                setIsActive(false);
              }}
              className="dropdown-item"
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </DropdownWrapper>
  );
}

export default Dropdown;
