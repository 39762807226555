import React, { useEffect, useRef, useState } from "react";
import MetaMaskOnboarding from "@metamask/onboarding";
import { useWeb3React } from "@web3-react/core";

import { LandingPageContanier } from "./style.js";
import BgVideo from "../../assets/landing_vid.mp4";
import BgPoster from "../../assets/landing_img.png";
import metaPortLogo from "../../assets/icons/metaport Logo.png";
import metaMaskLogo from "../../assets/icons/metamask logo.png";
import fortMaticLogo from "../../assets/icons/Fortmatic.png";
import walletConnectLogo from "../../assets/icons/WC.png";
import MixLogo from "../../assets/icons/Logos.png";

import Button from "../../components/button/buttons.js";
import { useIsMobile } from "../../utils/utils";
import { injected } from "../../web3/connectors.js";

export const LandingPage = ({ connectWC, connectFortmatic }) => {
  const isMobile = useIsMobile();
  const [showWallet, setShowWallet] = useState(false);
  const onboarding = useRef();
  const { activate } = useWeb3React();

  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
  }, []);

  const connectMM = async () => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      await activate(injected);
    } else {
      onboarding.current.startOnboarding();
    }
  };

  return (
    <>
      {isMobile && (
        <img
          alt="metaport-logo-mobile"
          src={metaPortLogo}
          height={34}
          style={{ margin: "3.5rem 1.5rem 0rem 1.5rem" }}
        ></img>
      )}
      <LandingPageContanier>
        <div className="content">
          {!isMobile && (
            <img alt="metaport-logo" src={metaPortLogo} className="logo"></img>
          )}
          <header className="metaverse-title">Teleport to Metaverses</header>
          <span className="metaverse-subtitle">
            The fastest & funnest way to move <br /> your identity and assets to
            <br /> multiple metaverses
          </span>
        </div>
        <div className="meta-mask-card">
          {showWallet ? (
            <>
              <span className="metamask-msg">Select your wallet</span>
              <div className="wallets-wrapper">
                <div className="wallet" onClick={connectMM}>
                  <img
                    alt="metamask-logo"
                    src={metaMaskLogo}
                    className="icons meta-mask-logo"
                  />
                  <span>Metamask</span>
                </div>
                <div className="wallet" onClick={connectFortmatic}>
                  <img
                    alt="fortmatic-logo"
                    src={fortMaticLogo}
                    className="icons fortmatic-logo"
                  />
                  <span>Fortmatic</span>
                </div>
                <div className="wallet" onClick={connectWC}>
                  <img
                    alt="walletConnect-logo"
                    src={walletConnectLogo}
                    className="icon-wc"
                  />
                  <span>WalletConnect</span>
                </div>
              </div>
            </>
          ) : (
            <>
              <span className="metamask-msg">
                Get started <br /> with your wallet
              </span>
              <span className="mix-icon">
                <img alt="metamask-logo" src={MixLogo} />
              </span>
              <Button isMobile={isMobile} onClick={() => setShowWallet(true)}>
                Link wallet
              </Button>
            </>
          )}
        </div>
        {isMobile ? (
          <img alt="metaport-logo" src={BgPoster} className="bgVideo"></img>
        ) : (
          <video
            className="bgVideo"
            poster={BgPoster}
            src={BgVideo}
            autoPlay
            muted
            loop
          ></video>
        )}
      </LandingPageContanier>
    </>
  );
};
