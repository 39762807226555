import Avatar1 from "../assets/img/mainavatar 1.png";
import Avatar2 from "../assets/img/Avatar2.png";
import Avatar3 from "../assets/img/Avatar3.png";

export const AvatarImages = [
  {
    id: 1,
    path: Avatar1,
  },
  {
    id: 2,
    path: Avatar2,
  },
  {
    id: 3,
    path: Avatar3,
  },
];
