import React from "react";
import { EmptyWrapper } from "./styles";

function Empty({ children }) {
  return (
    <EmptyWrapper>
      <div className="empty-text">{children}</div>
    </EmptyWrapper>
  );
}

export default Empty;
